<template>
  <section :class="`page ${currentDirectory.slug}`">
    <section class="hero has-hero-image is-between-small-and-medium">
      <div class="brand">
        <div
          id="hero_image"
          :style="'background-image: url(' + currentDirectory.hero_uri + ')'"
          :class="{ 'has-hero-color': !currentDirectory.hero_uri }"
        />
        <BackLink />
        <router-link v-if="logoUri" id="logo" to="/" aria-label="Home">
          <img class="logo" :src="logoUri" alt="Directory Hero">
        </router-link>
      </div>
      <div v-if="currentDirectory.description" id="stat-panel">
        <h1
          class="title is-size-1 p-3 has-text-centered"
          v-html="currentDirectory.name"
        />
        <div v-html="currentDirectory.description" />
      </div>
    </section>
    <div v-if="hasPrimaryFilter && featuredCategories.length > 0" class="section has-text-centered">
      <h1 class="title">
        FEATURED
      </h1>
      <FeaturedPrimaryFilterList />
    </div>
    <PrimaryFilterList />
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import FeaturedPrimaryFilterList from '@/components/InstantSearch/FeaturedPrimaryFilterList.vue';
import PrimaryFilterList from '@/components/InstantSearch/PrimaryFilterList.vue';
import BackLink from '@/components/Global/BackLink.vue';

export default {
  computed: {
    ...mapState({
      logoUri: (state) => state.logo_uri,
      instanceName: (state) => state.name,
    }),
    ...mapGetters({
      currentDirectory: 'directory/current',
      filterSettings: 'directory/primaryFilterSettings',
    }),
    hasPrimaryFilter() {
      return (this.featuredCategories?.length ?? 0) > 0;
    },
    featuredCategories() {
      return this.filterSettings.filter((facet) => facet?.isFeatured);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  components: {
    FeaturedPrimaryFilterList,
    PrimaryFilterList,
    BackLink,
  },
  metaInfo() {
    const { instanceName } = this;
    const directoryName = this.currentDirectory.name;
    const description = this.currentDirectory.meta_description;

    const title = `${directoryName} - ${instanceName} Directory`;
    const meta = [
      {
        name: 'description',
        content: description,
      },
    ];
    return { title, meta };
  },
};
</script>
