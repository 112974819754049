<template>
  <component
    :is="componentType"
    v-if="currentDirectory.primary_filter.field"
    class="primary-filter-list categories"
    :attribute="currentDirectory.primary_filter.field"
    :limit="200"
    :sort-by="sortBy"
    operator="and"
    :transform-items="transform"
  >
    <template slot-scope="{ createURL, items }">
      <div v-if="items.length > 0" class="section has-text-centered">
        <h1 class="title">
          ALL CATEGORIES
        </h1>
        <div class="has-3-columns">
          <a
            v-for="facet in items"
            :key="facet.value"
            class="is-4"
            :href="createURL(facet.value).replace('/home', '')"
          >
            <span class="is-unselectable">
              {{ facet.label }}
            </span>
          </a>
        </div>
      </div>
    </template>
  </component>
</template>

<script>
import { mapGetters } from 'vuex';
import componentMap from '@/mixins/componentMap';

export default {
  mixins: [componentMap],
  data: () => ({
    sortBy: ['name:asc', 'count:desc'],
  }),
  computed: {
    ...mapGetters({
      currentDirectory: 'directory/current',
      primaryFilter: 'directory/primaryFilterField',
      filterSettings: 'directory/primaryFilterSettings',
    }),
    componentType() {
      return this.componentMap[this.primaryFilter.type];
    },
    featuredFacets() {
      return this.filterSettings
        .filter((facet) => facet?.isFeatured)
        .map((facet) => facet.name);
    },
  },
  methods: {
    transform(items) {
      return items.filter((item) => !this.featuredFacets.includes(item.value));
    },
  },
};
</script>
